<template>
	<!-- 预出库汇总 -->
	<div class="preDeliverySummary">
		<!-- 导出模板 -->
		<div id="export" style="position: fixed;top: -9999999px;">
			<div class="export-template" v-for="(item, index) in selectionList" :key="index">
				<div :id="'template-title' + index">
					<div style="line-height: 50px;font-size: 22px;" align=center>
						<strong>
							<font>配送商品汇总</font>
							<!-- <font>{{ routeName }}-配送商品汇总</font> -->
						</strong>
					</div>
				</div>
				<div class="title-box">
					<div class="box-l">
						<div class="l-item">
							<span>配送日期：{{ searchForm.date }}</span>
						</div>
					</div>
				</div>
				<div :id="'template-table' + index">
					<table border=1 cellSpacing=0 cellPadding=1 width="100%" style="border-collapse:collapse"
						bordercolor="#333333">
						<thead>
							<tr>
								<td width="15%">
									<div align=center><b>序号</b></div>
								</td>
								<td width="70%">
									<div align=center><b>商品名称</b></div>
								</td>
								<td width="15%">
									<div align=center><b>数量</b></div>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td align=center>{{ index + 1 }}</td>
								<td align=center>{{ item.goods_name }}</td>
								<td align=center>{{ item.count }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label" style="width: 90px;">配送日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label" style="width: 90px;">配送单号:</div>
					<div class="item-input">
						<el-input v-model="searchForm.sn" placeholder="请输入配送单号" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label" style="width: 90px;">联系人:</div>
					<div class="item-input">
						<el-input v-model="searchForm.contacts" placeholder="请输入联系人" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label" style="width: 90px;">电话:</div>
					<div class="item-input">
						<el-input v-model="searchForm.phone" placeholder="请输入电话" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label" style="width: 90px;">地址:</div>
					<div class="item-input">
						<el-input v-model="searchForm.address" placeholder="请输入地址" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label" style="width: 90px;">配送单类型:</div>
					<div class="item-input">
						<el-select v-model="searchForm.type" placeholder="请选择配送单类型">
							<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label" style="width: 90px;">商品状态:</div>
					<div class="item-input">
						<el-select v-model="searchForm.status" placeholder="请选择商品状态">
							<el-option v-for="item in statusType" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label" style="width: 90px;">商品名称:</div>
					<div class="item-input">
						<el-input v-model="searchForm.goods_name" placeholder="请输入商品名称" />
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
					<el-button
						@click="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="success" @click="handleExport">批量打印</el-button>
				<el-button type="warning" @click="handleDelay(1)" :disabled="delayDisabled">批量延期</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()" @selection-change="selectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="sn" label="配送单号" align="center"></el-table-column>
				<el-table-column prop="contacts" label="联系人" align="center"></el-table-column>
				<el-table-column prop="phone" label="电话" align="center"></el-table-column>
				<el-table-column prop="address" label="地址" align="center"></el-table-column>
				<el-table-column label="单据类型" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">正常</span>
						<span v-if="scope.row.type == 2">延期</span>
					</template>
				</el-table-column>
				<el-table-column label="提货方式" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.delivery_type == 1">配送</span>
						<span v-if="scope.row.delivery_type == 2">自提</span>
					</template>
				</el-table-column>
				<el-table-column prop="goods_name" label="商品名称" align="center">
					<template slot-scope="scope">
						<span v-if="searchForm.goods_name && scope.row.goods_name.indexOf(searchForm.goods_name)>=0"
							style="color:#5500ff;font-weight: 600;">{{ scope.row.goods_name }}</span>
						<span v-else>{{ scope.row.goods_name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="商品图片" align="center">
					<template slot-scope="scope">
						<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
							:preview-src-list="[scope.row.goods_pic]">
						</el-image>
					</template>
				</el-table-column>
				<el-table-column prop="count" label="数量" align="center"></el-table-column>
				<el-table-column prop="price" label="订单价" align="center"></el-table-column>
				<el-table-column prop="amount" label="订单金额" align="center"></el-table-column>
				<el-table-column label="商品状态" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 0">未确认</span>
						<span v-if="scope.row.status == 1">已确认</span>
						<span v-if="scope.row.status == 2" style="color: red;">已延期</span>
						<span v-if="scope.row.status == 9" style="color: #ccc;">已取消</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="warning" size="mini" v-if="scope.row.status == 0"
							@click="handleDelay(2, scope.row)">延期</el-button>
						<el-button type="success" size="mini" @click="handleExport(scope.row)">打印</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 延期 -->
		<el-dialog title="商品延期" :visible.sync="delayDialogVisible" :close-on-click-modal="false" v-el-drag-dialog
			width="30%">
			<el-form ref="form" :model="delayForm" label-width="100px">
				<el-form-item label="延期数量" v-if="delayType == 2">
					<el-input v-model="delayForm.count" placeholder="请输入延期数量"></el-input>
				</el-form-item>
				<el-form-item label="配送日期">
					<el-date-picker v-model="delayForm.date" type="date" placeholder="选择配送日期" value-format="yyyy-MM-dd"
						:picker-options="pickerOptions" style="width:100%">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="提货方式" v-if="delayType == 2">
					<el-select v-model="delayForm.delivery_type" placeholder="请选择提货方式" style="width: 100%;">
						<el-option v-for="item in deliveryType" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属路线" v-if="delayType == 2 && delayForm.delivery_type == 1">
					<el-select v-model="delayForm.delivery_line_id" placeholder="请选择所属路线" style="width: 100%;">
						<el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="运费费用" v-if="delayType == 2 && delayForm.delivery_type == 1">
					<el-input v-model="delayForm.delivery_fee" placeholder="请输入运费费用"></el-input>
				</el-form-item>
				<el-form-item label="运费是否支付" v-if="delayType == 2 && delayForm.delivery_type == 1">
					<el-select v-model="delayForm.delivery_fee_status" placeholder="请选择运费是否支付" style="width: 100%;">
						<el-option v-for="item in deliveryFeeStatus" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="delayDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="delayConfirmFn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getLodop
	} from '@/utils/CLodopfuncs.js'
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
					status: '',
					delivery_line_id: ''
				},
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				selectionList: [],
				typeList: [{
						id: 1,
						name: '正常'
					},
					{
						id: 2,
						name: '延期'
					},
				],
				// 状态
				statusType: [{
						id: 0,
						name: '未确认'
					},
					{
						id: 1,
						name: '已确认'
					},
					{
						id: 2,
						name: '已延期'
					},
					{
						id: 9,
						name: '已取消'
					}
				],
				routeList: [], //配送路线
				routeName: '',
				delayDialogVisible: false,
				delayType: '',
				batchDelayArr: [],
				delayForm: {},
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					},
				},
				// 提货方式
				deliveryType: [{
						id: 1,
						name: '配送'
					},
					{
						id: 2,
						name: '自提'
					}
				],
				// 运费是否支付
				deliveryFeeStatus: [{
						id: 0,
						name: '未付'
					},
					{
						id: 1,
						name: '已付'
					}
				],
				delayDisabled: false, //批量延期
			}
		},
		mounted() {
			// 时间
			this.dateVal = [this.getCurrentDate(), this.getCurrentDate()]
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			// 列表
			this.getList()
			this.getRouteList()
		},
		methods: {
			getList() {
				this.$http.post(`erp/v1/preck/lst_goods`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 所属路线
			getRouteList() {
				this.$http.post(`erp/v1/delivery_line/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.routeList = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 选择表格数据
			selectionChange(e) {
				this.selectionList = e
				// 批量延期
				const delayArr = this.selectionList.every(i => {
					return i.status == 0
				})
				if (delayArr) {
					this.delayDisabled = false
				} else {
					this.delayDisabled = true
				}
			},
			// 延期
			handleDelay(type, item) {
				this.delayType = type
				if (this.delayType == 1) {
					if (this.selectionList.length <= 0) {
						this.$message.error('请选择批量延期的数据');
						return
					}
				}
				if (type == 1) {
					this.selectionList.forEach(item => {
						this.batchDelayArr.push({
							pre_ckd_mx_id: item.id,
							count: item.count,
						})
					});
					this.delayForm.detail = JSON.stringify(this.batchDelayArr)
				} else {
					this.delayForm.pre_ckd_mx_id = item.id
				}
				this.delayDialogVisible = true
			},
			// 延期确认
			delayConfirmFn() {
				let url = this.delayType == 1 ? 'erp/v1/preck/batch_goods_delay' : 'erp/v1/preck/goods_delay'
				this.$http.post(`${url}`, {
					...this.delayForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.delayDialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 打印
			handleExport(item) {
				if (item.sn) {
					this.selectionList = []
					this.selectionList.push(item)
				} else {
					if (this.selectionList.length <= 0) {
						this.$message.error('请选择导出数据');
						return
					}
				}
				setTimeout(() => {
					var LODOP = getLodop();
					var titleStyle =
						"<style> .title-box{display: flex;justify-content: space-between;margin-bottom:10px} .title-box .l-item {line-height: 22px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r img {width: 100px;height: 100px;}</style>"
					LODOP.ADD_PRINT_HTM(2, "5%", "90%", 1000, titleStyle + document.getElementById("export")
						.innerHTML);
					LODOP.PREVIEW()
					this.selectionList = []
					this.getList()
				}, 500);
			},
		},
	}
</script>

<style lang="scss" scoped></style>